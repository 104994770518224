import $ from 'jquery';
import 'slick-carousel';

$(document).ready(function() {
	$('.slider_exemple').slick({
		arrows: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1
	});
});